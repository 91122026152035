import axios from "axios";
import {
  RESULT_BULK_CREATE_FAIL,
  RESULT_BULK_CREATE_REQUEST,
  RESULT_BULK_CREATE_RESET,
  RESULT_BULK_CREATE_SUCCESS,
  RESULT_CREATE_FAIL,
  RESULT_CREATE_REQUEST,
  RESULT_CREATE_SUCCESS,
  RESULT_DELETE_FAIL,
  RESULT_DELETE_REQUEST,
  RESULT_DELETE_SUCCESS,
  RESULT_DETAILS_FAIL,
  RESULT_DETAILS_REQUEST,
  RESULT_DETAILS_RESET,
  RESULT_DETAILS_SUCCESS,
  RESULT_LIST_FAIL,
  RESULT_LIST_REQUEST,
  RESULT_LIST_SUCCESS,
  RESULT_UPDATE_FAIL,
  RESULT_UPDATE_REQUEST,
  RESULT_UPDATE_RESET,
  RESULT_UPDATE_SUCCESS,
} from "../types/resultTypes";
import { logout } from "./userActions";

export const listResults =
  (keyword = "", pageNumber = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: RESULT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/results?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: RESULT_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, Token failed") {
        dispatch(logout());
      }
      dispatch({
        type: RESULT_LIST_FAIL,
        payload: message,
      });
    }
  };

export const getResultDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RESULT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const res = await axios.get(`/api/results/${id}`, config);
    dispatch({
      type: RESULT_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, Token failed") {
      dispatch(logout());
    }
    dispatch({
      type: RESULT_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const bulkCreateResult = (results) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESULT_BULK_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/results/bulk`, results, config);

    dispatch({
      type: RESULT_BULK_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: RESULT_BULK_CREATE_FAIL,
      payload: message,
    });
  }
};

export const createResult =
  (fullName, courseTitle, grade, matricNumber, graduationYear, school) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RESULT_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/results/`,
        { fullName, courseTitle, grade, matricNumber, graduationYear, school },
        config
      );

      dispatch({
        type: RESULT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: RESULT_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const deleteResult = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESULT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/results/${id}`, config);

    dispatch({
      type: RESULT_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: RESULT_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateResult = (result) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESULT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/results/${result._id}`,
      result,
      config
    );

    dispatch({
      type: RESULT_UPDATE_SUCCESS,
    });

    dispatch({
      type: RESULT_DETAILS_SUCCESS,
      payload: data,
    });

    dispatch({ type: RESULT_DETAILS_RESET });
  } catch (error) {
    dispatch({
      type: RESULT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetBulkCreateResult = () => (dispatch) =>
  dispatch({ type: RESULT_BULK_CREATE_RESET });

export const resetUpdateResult = () => (dispatch) =>
  dispatch({ type: RESULT_UPDATE_RESET });
