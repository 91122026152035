import React, { useEffect, useState } from "react";
import FormContainer from "../components/FormContainer";
import AddCheckSteps from "../components/AddCheckSteps";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { saveGuarantorDetails } from "../actions/checkActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const GuarantorScreen = () => {
  const checkForm = useSelector((state) => state.checkForm);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState(
    checkForm.guarantorDetails ? checkForm.guarantorDetails.name : ""
  );
  const [email, setEmail] = useState(
    checkForm.guarantorDetails ? checkForm.guarantorDetails.email : ""
  );
  const [phone, setPhone] = useState(
    checkForm.guarantorDetails ? checkForm.guarantorDetails.phone : ""
  );
  const [address, setAddress] = useState(
    checkForm.guarantorDetails ? checkForm.guarantorDetails.address : ""
  );

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (checkForm.personalDetails && !checkForm.personalDetails.name) {
      navigate("/personal");
    }
  }, [navigate, userInfo, checkForm.personalDetails]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveGuarantorDetails({
        name,
        email,
        phone,
        address,
      })
    );
    navigate("/referee");
  };

  return (
    <FormContainer>
      <AddCheckSteps step1 step2 />
      <h3>Guarantor Details</h3>
      <Form onSubmit={submitHandler}>
        <FormGroup className="py-3">
          <FormLabel>Guarantor Name</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter Guarantor Name"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Guarantor Email</FormLabel>
          <FormControl
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Enter Guarantor Email"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Guarantor Phone</FormLabel>
          <FormControl
            type="number"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="Enter Guarantor Phone"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Guarantor Address</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder="Enter Guarantor Address"
            required
          ></FormControl>
        </FormGroup>

        <Row className="mt-3">
          <Col>
            <LinkContainer to="/personal">
              <Button className="btn-sm">Go back</Button>
            </LinkContainer>
          </Col>
          <Col className="text-right">
            <Button type="submit" className="btn-info btn-sm">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

export default GuarantorScreen;
