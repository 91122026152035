import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from './components/Header';
import HomeScreen from './screens/HomeScreen';

import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import RegisterSuccessScreen from './screens/RegisterSuccessScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import ReportEditScreen from './screens/ReportEditScreen';
import ReportListScreen from './screens/ReportListScreen';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ResultListScreen from './screens/ResultListScreen';
import ResultEditScreen from './screens/ResultEditScreen';
import CheckForm from './screens/CheckForm';
import CheckListScreen from './screens/CheckListScreen';
import CheckScreen from './screens/CheckScreen';
import PersonalScreen from './screens/PersonalScreen';
import GuarantorScreen from './screens/GuarantorScreen';
import RefereeScreen from './screens/RefereeScreen';
import PrevempScreen from './screens/PrevempScreen';
import ConfirmScreen from './screens/ConfirmScreen';

const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Routes>
            <Route path='/users' element={<UserListScreen />} />
            <Route path='/user/:id/edit' element={<UserEditScreen />} exact />
            <Route path='/reports' element={<ReportListScreen />} exact />
            <Route path='/results' element={<ResultListScreen />} exact />
            <Route path='/checks' element={<CheckListScreen />} exact />
            <Route path='/start-a-check' element={<CheckForm />} exact />
            <Route path='/personal' element={<PersonalScreen />} exact />
            <Route path='/guarantor' element={<GuarantorScreen />} exact />
            <Route path='/referee' element={<RefereeScreen />} exact />
            <Route path='/prevemp' element={<PrevempScreen />} exact />
            <Route path='/confirm' element={<ConfirmScreen />} exact />

            <Route path='/check/:id' element={<CheckScreen />} exact />
            <Route
              path='/result/:id/edit'
              element={<ResultEditScreen />}
              exact
            />
            <Route
              path='/auth/forgot-password'
              element={<ForgotPassword />}
              exact
            />
            <Route
              path='/auth/reset-password/:token'
              element={<ResetPassword />}
              exact
            />
            <Route
              path='/page/:pageNumber'
              element={<ResultListScreen />}
              exact
            />
            <Route
              path='/checkpage/:pageNumber'
              element={<CheckListScreen />}
              exact
            />
            <Route
              path='/reportpage/:pageNumber'
              element={<ReportListScreen />}
              exact
            />
            <Route
              path='/report/:id/edit'
              element={<ReportEditScreen />}
              exact
            />
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/signup' element={<RegisterScreen />} exact />
            <Route
              path='/search/:keyword'
              element={<ResultListScreen />}
              exact
            />
            <Route
              path='/searchreport/:keyword'
              element={<ReportListScreen />}
              exact
            />
            <Route
              path='/search/:keyword/page/:pageNumber'
              element={<ResultListScreen />}
              exact
            />
            <Route
              path='/searchreport/:keyword/reportpage/:pageNumber'
              element={<ReportListScreen />}
              exact
            />
            <Route
              path='/signup-success'
              element={<RegisterSuccessScreen />}
              exact
            />

            <Route path='/' element={<HomeScreen />} exact />
          </Routes>
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
