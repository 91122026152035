import React from "react";

const RegisterSuccessScreen = () => {
  return (
    <div style={{ margin: "0 auto" }}>
      <h1 className="py-3 text-center">Your Registration was Successful!</h1>
      <h4 className="text-center">We'll get in touch with you shortly...</h4>
    </div>
  );
};

export default RegisterSuccessScreen;
