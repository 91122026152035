import {
  CHECK_BULK_CREATE_FAIL,
  CHECK_BULK_CREATE_REQUEST,
  CHECK_BULK_CREATE_RESET,
  CHECK_BULK_CREATE_SUCCESS,
  CHECK_COMPLETE_FAIL,
  CHECK_COMPLETE_REQUEST,
  CHECK_COMPLETE_RESET,
  CHECK_COMPLETE_SUCCESS,
  CHECK_CREATE_FAIL,
  CHECK_CREATE_REQUEST,
  CHECK_CREATE_RESET,
  CHECK_CREATE_SUCCESS,
  CHECK_DELETE_FAIL,
  CHECK_DELETE_REQUEST,
  CHECK_DELETE_SUCCESS,
  CHECK_DETAILS_FAIL,
  CHECK_DETAILS_REQUEST,
  CHECK_DETAILS_RESET,
  CHECK_DETAILS_SUCCESS,
  CHECK_FORM_RESET,
  CHECK_LIST_FAIL,
  CHECK_LIST_REQUEST,
  CHECK_LIST_RESET,
  CHECK_LIST_SUCCESS,
  CHECK_PAY_FAIL,
  CHECK_PAY_REQUEST,
  CHECK_PAY_RESET,
  CHECK_PAY_SUCCESS,
  MYCHECK_LIST_FAIL,
  MYCHECK_LIST_REQUEST,
  MYCHECK_LIST_RESET,
  MYCHECK_LIST_SUCCESS,
  SAVE_GUARANTOR_DETAILS,
  SAVE_PERSONAL_DETAILS,
  SAVE_PREVEMP_DETAILS,
  SAVE_REFEREE_DETAILS,
} from '../types/checkTypes';

export const checkCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_CREATE_REQUEST:
      return { loading: true };
    case CHECK_CREATE_SUCCESS:
      return { loading: false, success: true, check: action.payload };
    case CHECK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CHECK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const checkBulkCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_BULK_CREATE_REQUEST:
      return { loading: true };
    case CHECK_BULK_CREATE_SUCCESS:
      return { loading: false, success: true, check: action.payload };
    case CHECK_BULK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CHECK_BULK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const checkListReducer = (state = { checks: [] }, action) => {
  switch (action.type) {
    case CHECK_LIST_REQUEST:
      return { loading: true };
    case CHECK_LIST_SUCCESS:
      return {
        loading: false,
        checks: action.payload.checks,
        pages: action.payload.pages,
        page: action.payload.page,
        totalChecks: action.payload.totalChecks
      };
    case CHECK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CHECK_LIST_RESET:
      return {
        checks: [],
      };

    default:
      return state;
  }
};

export const myCheckListReducer = (state = { checks: [] }, action) => {
  switch (action.type) {
    case MYCHECK_LIST_REQUEST:
      return { loading: true };
    case MYCHECK_LIST_SUCCESS:
      return {
        myLoading: false,
        myChecks: action.payload.checks,
        myPages: action.payload.pages,
        myPage: action.payload.page,
        myTotalChecks: action.payload.totalChecks
      };
    case MYCHECK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case MYCHECK_LIST_RESET:
      return {
        checks: [],
      };

    default:
      return state;
  }
};

export const checkDetailsReducer = (state = { check: {} }, action) => {
  switch (action.type) {
    case CHECK_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHECK_DETAILS_SUCCESS:
      return {
        loading: false,
        check: action.payload,
      };
    case CHECK_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CHECK_DETAILS_RESET:
      return {
        check: {},
      };

    default:
      return state;
  }
};

export const checkDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_DELETE_REQUEST:
      return { loading: true };
    case CHECK_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CHECK_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const checkFormReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_PERSONAL_DETAILS:
      return { ...state, personalDetails: action.payload };

    case SAVE_GUARANTOR_DETAILS:
      return { ...state, guarantorDetails: action.payload };

    case SAVE_REFEREE_DETAILS:
      return { ...state, refereeDetails: action.payload };

    case SAVE_PREVEMP_DETAILS:
      return { ...state, prevempDetails: action.payload };

    case CHECK_FORM_RESET:
      return {};
    default:
      return state;
  }
};

export const checkPayReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_PAY_REQUEST:
      return {
        loading: true,
      }
    case CHECK_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CHECK_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case CHECK_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const checkCompleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_COMPLETE_REQUEST:
      return {
        loading: true,
      }
    case CHECK_COMPLETE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CHECK_COMPLETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case CHECK_COMPLETE_RESET:
      return {}
    default:
      return state
  }
}
