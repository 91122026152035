import {
  MYREPORT_LIST_FAIL,
  MYREPORT_LIST_REQUEST,
  MYREPORT_LIST_SUCCESS,
  REPORT_CREATE_FAIL,
  REPORT_CREATE_REQUEST,
  REPORT_CREATE_RESET,
  REPORT_CREATE_SUCCESS,
  REPORT_DELETE_FAIL,
  REPORT_DELETE_REQUEST,
  REPORT_DELETE_SUCCESS,
  REPORT_DETAILS_FAIL,
  REPORT_DETAILS_REQUEST,
  REPORT_DETAILS_RESET,
  REPORT_DETAILS_SUCCESS,
  REPORT_LIST_FAIL,
  REPORT_LIST_REQUEST,
  REPORT_LIST_SUCCESS,
  REPORT_UPDATE_FAIL,
  REPORT_UPDATE_REQUEST,
  REPORT_UPDATE_RESET,
  REPORT_UPDATE_SUCCESS,
} from "../types/reportTypes";

export const reportListReducer = (state = { reports: [] }, action) => {
  switch (action.type) {
    case REPORT_LIST_REQUEST:
      return { loading: true, reports: [] };
    case REPORT_LIST_SUCCESS:
      return {
        loading: false,
        reports: action.payload.reports,
        pages: action.payload.pages,
        page: action.payload.page,
        totalReports: action.payload.totalReports
      };
    case REPORT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const myReportListReducer = (state = { reports: [] }, action) => {
  switch (action.type) {
    case MYREPORT_LIST_REQUEST:
      return { loading: true, reports: [] };
    case MYREPORT_LIST_SUCCESS:
      return {
        myLoading: false,
        myReports: action.payload.reports,
        myPages: action.payload.pages,
        myPage: action.payload.page,
        myTotalReports: action.payload.totalReports
      };
    case MYREPORT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const reportDetailsReducer = (state = { report: {} }, action) => {
  switch (action.type) {
    case REPORT_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case REPORT_DETAILS_SUCCESS:
      return {
        loading: false,
        report: action.payload,
      };
    case REPORT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case REPORT_DETAILS_RESET:
      return {
        report: {},
      };

    default:
      return state;
  }
};

export const reportDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_DELETE_REQUEST:
      return { loading: true };
    case REPORT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case REPORT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const reportCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_CREATE_REQUEST:
      return { loading: true };
    case REPORT_CREATE_SUCCESS:
      return { loading: false, success: true, report: action.payload };
    case REPORT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case REPORT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const reportUpdateReducer = (state = { report: {} }, action) => {
  switch (action.type) {
    case REPORT_UPDATE_REQUEST:
      return { loading: true };
    case REPORT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case REPORT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REPORT_UPDATE_RESET:
      return {
        report: {},
      };

    default:
      return state;
  }
};
