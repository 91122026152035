import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import MainArea from "../components/MainArea";

const HomeScreen = () => {
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.verified) {
      navigate("/login");
    }
  }, [userInfo, navigate]);
  return (
    <>
      <Row className="py-5">
        <Col xs={12} md={3}>
          <Sidebar />
        </Col>
        <Col xs={12} md={9}>
          <MainArea />
        </Col>
      </Row>
    </>
  );
};

export default HomeScreen;
