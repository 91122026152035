import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkCreateReset, createCheck } from "../actions/checkActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { LinkContainer } from "react-router-bootstrap";

const ConfirmScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { prevempDetails } = useSelector((state) => state.checkForm);
  const { personalDetails } = useSelector((state) => state.checkForm);
  const { guarantorDetails } = useSelector((state) => state.checkForm);
  const { refereeDetails } = useSelector((state) => state.checkForm);

  const { loading, error, success } = useSelector((state) => state.checkCreate);
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (prevempDetails && !prevempDetails.name) {
      navigate("/prevemp");
    }
    if (success) {
      navigate("/checks");
      dispatch(checkCreateReset());
    }
  }, [navigate, userInfo, prevempDetails, success, dispatch]);

  const handleSubmit = () => {
    dispatch(
      createCheck(
        personalDetails,
        guarantorDetails,
        refereeDetails,
        prevempDetails
      )
    );
  };

  return (
    <>
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      <h3>Confirm Check Details</h3>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-dark">Personal Details</Card.Title>
              <Card.Text>
                <ListGroup>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Name: </span>
                      <span>{personalDetails && personalDetails.name}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Address: </span>{" "}
                      <span>{personalDetails && personalDetails.address}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    {personalDetails && personalDetails.institution && (
                      <p>
                        <span className="bold">Institution: </span>{" "}
                        <span>{personalDetails.institution}</span>
                      </p>
                    )}
                  </ListGroupItem>
                  {personalDetails && personalDetails.matricNumber && (
                    <ListGroupItem>
                      <p>
                        <span className="bold">Matric Number: </span>
                        <span>{personalDetails.matricNumber}</span>
                      </p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-dark">Guarantor Details</Card.Title>
              <Card.Text>
                <ListGroup>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Guarantor Name:</span>{" "}
                      <span>{guarantorDetails && guarantorDetails.name}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Guarantor Email:</span>{" "}
                      <span>{guarantorDetails && guarantorDetails.email}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Guarantor Phone:</span>{" "}
                      <span>{guarantorDetails && guarantorDetails.phone}</span>
                    </p>
                  </ListGroupItem>

                  <ListGroupItem>
                    <p>
                      <span className="bold">Guarantor Address:</span>{" "}
                      <span>
                        {guarantorDetails && guarantorDetails.address}
                      </span>
                    </p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-dark">Referee Details</Card.Title>
              <Card.Text>
                <ListGroup>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Referee Name:</span>{" "}
                      <span>{refereeDetails && refereeDetails.name}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Referee Email: </span>
                      <span>{refereeDetails && refereeDetails.email}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Referee Phone:</span>{" "}
                      <span>{refereeDetails && refereeDetails.phone}</span>
                    </p>
                  </ListGroupItem>
                  {refereeDetails && refereeDetails.agency && (
                    <ListGroupItem>
                      <p>
                        <span className="bold">Seconded By Agency:</span>{" "}
                        <span>{refereeDetails.agency}</span>
                      </p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card>
            <Card.Body>
              <Card.Title className="text-dark">
                Previous Employer Details
              </Card.Title>
              <Card.Text>
                <ListGroup>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Previous Employer Name:</span>{" "}
                      <span>{prevempDetails && prevempDetails.name}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Previous Employer Address:</span>{" "}
                      <span>{prevempDetails && prevempDetails.address}</span>
                    </p>
                  </ListGroupItem>
                  <ListGroupItem>
                    <p>
                      <span className="bold">Previous Employer HR Email:</span>{" "}
                      <span>{prevempDetails && prevempDetails.email}</span>
                    </p>
                  </ListGroupItem>

                  <ListGroupItem>
                    <p>
                      <span className="bold">Previous Employer HR Phone:</span>{" "}
                      <span>{prevempDetails && prevempDetails.phone}</span>
                    </p>
                  </ListGroupItem>
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <LinkContainer to="/prevemp">
            <Button className="btn-sm">Go back</Button>
          </LinkContainer>
        </Col>
        <Col className="text-right">
          <Button className="btn-success btn-sm" onClick={handleSubmit}>
            Confirm & Finish
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmScreen;
