import React from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import { Link } from "react-router-dom";
// import { LinkContainer } from "react-router-bootstrap";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <Card>
        <Card.Body>
          <h5 className="pt-3">
            Welcome, {userInfo && userInfo?.name?.trim().split(" ")[0]}!
          </h5>
          <p className="py-2">{userInfo && userInfo?.officeAddress}</p>
          <Row>
            {/* {userInfo && !userInfo.isAdmin && (
              <Col md={6}>
                <LinkContainer to="/personal">
                  <Button className="btn-sm btn-info">
                    <i className="fas fa-plus" /> Check
                  </Button>
                </LinkContainer>
              </Col>
            )} */}
            <Col>
              <Button className="py-2 btn-sm" onClick={logoutHandler}>
                <i className="fas fa-sign-out" /> Logout
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="my-5">
        <Card.Body>
          <h5 className="pt-5">
            {" "}
            <i
              className="fas fa-file-pdf"
              style={{ color: " #ff6634", fontSize: "22px" }}
            />{" "}
            Reports
          </h5>
          <ListGroup>
            <ListGroupItem>
              <i
                className="fas fa-eye"
                style={{ color: " #ff6634", fontSize: "19px" }}
              />{" "}
              <Link to="/reports">View Reports</Link>
            </ListGroupItem>
          </ListGroup>

          <h5 className="pt-5">
            {" "}
            <i
              className="fas fa-check-square"
              style={{ color: " #ff6634", fontSize: "22px" }}
            />{" "}
            Checks
          </h5>
          <ListGroup>
            <ListGroupItem>
              <i
                className="fas fa-eye"
                style={{ color: " #ff6634", fontSize: "19px" }}
              />{" "}
              <Link to="/checks">View Checks</Link>
            </ListGroupItem>
          </ListGroup>

          {userInfo && userInfo.isAdmin && (
            <>
              <h5 className="pt-5">
                <i
                  className="fas fa-users"
                  style={{ color: " #ff6634", fontSize: "22px" }}
                />{" "}
                Users
              </h5>
              <ListGroup>
                <ListGroupItem>
                  <i
                    className="fas fa-eye"
                    style={{ color: " #ff6634", fontSize: "19px" }}
                  />{" "}
                  <Link to="/users"> View Users</Link>
                </ListGroupItem>
              </ListGroup>

              <h5 className="pt-5">
                {" "}
                <i
                  className="fas fa-certificate"
                  style={{ color: " #ff6634", fontSize: "22px" }}
                />{" "}
                Results
              </h5>
              <ListGroup>
                <ListGroupItem>
                  <i
                    className="fas fa-eye"
                    style={{ color: " #ff6634", fontSize: "19px" }}
                  />{" "}
                  <Link to="/results">View Results</Link>
                </ListGroupItem>
              </ListGroup>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Sidebar;
