import React, { useEffect, useState, useRef } from "react";
import { Form, Button, FormControl, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as xlsx from "xlsx";
import {
  bulkCreateResult,
  deleteResult,
  listResults,
  resetBulkCreateResult,
} from "../actions/resultActions";
import Paginate from "../components/Paginate";
import { LinkContainer } from "react-router-bootstrap";
import Loader from "../components/Loader";
import Message from "../components/Message";

import AddResultModal from "../components/AddResultModal";

const ResultListScreen = () => {
  const [excelRows, setExcelRows] = useState([]);
  const inputRef = useRef();
  const [modalShow, setModalShow] = useState(false);
  const [successCreate, setSuccessCreate] = useState(false);

  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const keyword = useParams().keyword;
  const pageNumber = useParams().pageNumber || 1;

  const { loading, error, results, pages, page } = useSelector(
    (state) => state.resultList
  );
  const { success } = useSelector((state) => state.resultBulkCreate);

  const {
    success: successDelete,
    error: errorDelete,
    loading: loadingDelete,
  } = useSelector((state) => state.resultDelete);

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listResults(keyword, pageNumber));
    } else {
      navigate("/login");
    }

    if (success) {
      dispatch(resetBulkCreateResult());
    }
  }, [
    navigate,
    userInfo,
    pageNumber,
    keyword,
    dispatch,
    success,
    successDelete,
    successCreate,
  ]);

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return element.matricNumber === el.matricNumber;
      });
    });
    return res;
  };

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteResult(id));
    }
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const fileObj = e.target.files;

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        const list = filterByReference(json, results);
        console.log(list);
        setExcelRows(list);
      };
      reader.readAsArrayBuffer(fileObj[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(bulkCreateResult(excelRows));
    inputRef.current.value = "";
  };

  return (
    <>
      <Link to="/" className="btn btn-info my-3">
        Go Back
      </Link>

      <AddResultModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onCreate={() => setSuccessCreate(true)}
      />
      <Row>
        <Col md={2}>
          <h1>Results</h1>
        </Col>

        <Col md={6} className="pt-2">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                {" "}
                <FormControl
                  size="md"
                  type="file"
                  label="Choose excel"
                  onChange={readUploadFile}
                  ref={inputRef}
                ></FormControl>
              </Col>
              <Col>
                <Button type="submit" className="btn-md btn-warning">
                  Bulk Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col className="text-right pt-2">
          <Button
            type="button"
            onClick={() => setModalShow(true)}
            className="btn btn-info"
          >
            <i className="fas fa-plus" /> Add
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message>{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table bordered striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>FULL NAME</th>
                <th>COURSE TITLE</th>
                <th>GRADE</th>
                <th>MATRIC NUMBER</th>
                <th>GRAD YEAR</th>
                <th>SCHOOL</th>
                <th>UPDATED AT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {results.length > 0 &&
                results.map((result) => (
                  <tr key={result._id}>
                    <td>{result._id.slice(-6)}</td>
                    <td>{result.fullName}</td>

                    <td>{result.courseTitle}</td>
                    <td>{result.grade}</td>
                    <td>{result.matricNumber}</td>
                    <td>{result.graduationYear}</td>
                    <td>{result.school}</td>
                    <td>{result.createdAt.substring(0, 10)}</td>

                    <td>
                      <LinkContainer to={`/result/${result._id}/edit`}>
                        <Button className="btn-sm">
                          <i className="fas fa-edit" />
                        </Button>
                      </LinkContainer>
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => deleteHandler(result._id)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page} keyword={keyword} />
        </>
      )}
    </>
  );
};

export default ResultListScreen;
