import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  listUsers,
  getUserDetails,
  userProfileUpdateReset,
  updateUserProfile,


} from '../actions/userActions';
import { listMyReports, listReports } from '../actions/reportActions';
import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  Card,
  Row,
  Col,
  FormControl,
  Form,
  FormLabel,
  FormGroup,
  Button,
} from 'react-bootstrap';
import { listResults } from '../actions/resultActions';
import { listChecks, listMyChecks } from '../actions/checkActions';
import { LinkContainer } from 'react-router-bootstrap';

const MainArea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [officeAddress, setOfficeAddress] = useState('');
  const [message, setMessage] = useState(null);


  const { userInfo } = useSelector((state) => state.userLogin);

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const { totalChecks } = useSelector(
    (state) => state.checkList
  );

  const { myTotalChecks } = useSelector(
    (state) => state.myCheckList
  );

  const { results, loading: loadingResults } = useSelector(
    (state) => state.resultList
  );

  const userProfileUpdate = useSelector((state) => state.userProfileUpdate);
  const { success } = userProfileUpdate;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading: loadingUser, user, error: errorUser } = userDetails;

  const { totalReports } = useSelector(
    (state) => state.reportList
  );

  const { myTotalReports } = useSelector(
    (state) => state.myReportList
  );



  const clients =
    !loading && users && users.filter((user) => user.type === 'Client');

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      if (!user || !user.name || success) {
        dispatch(userProfileUpdateReset());
        dispatch(getUserDetails('profile'));
      } else {
        setName(user.name);
        setEmail(user.email);
        setPhonenumber(user.phonenumber);
        setOfficeAddress(user.officeAddress);
      }
      dispatch(listMyReports());
      dispatch(listMyChecks());

      if (userInfo.isAdmin) {
        dispatch(listUsers());
        dispatch(listResults());
        dispatch(listChecks())
        dispatch(listReports());
      }
    }

  }, [dispatch, userInfo, navigate, user, success]);

  const updateUserHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          email,
          password,
          phonenumber,
          officeAddress,
        })
      );
    }
  };

  return (
    <>
      {message && <Message variant='danger'>{message}</Message>}
      {success && <Message variant='success'>Profile Updated</Message>}
      {loadingUser && <Loader />}
      {errorUser && <Message>{errorUser}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row className='d-flex justify-content-center'>
            {userInfo && userInfo.isAdmin ? (
              <>
                <Col md={3} xs={6}>
                  <LinkContainer to='/results' style={{ cursor: 'pointer' }}>
                    <Card className='text-center py-4'>
                      <Card.Body>
                        <h1>{!loadingResults && results.length}</h1>
                        <h6>
                          {!loadingResults && results.length > 1
                            ? 'Results'
                            : 'Result'}
                        </h6>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>

                <Col md={3} xs={6}>
                  <LinkContainer to='/users' style={{ cursor: 'pointer' }}>
                    <Card className='text-center py-4'>
                      <Card.Body>
                        <h1>{clients.length}</h1>
                        <h6>{clients.length > 1 ? 'Clients' : 'Client'}</h6>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>

                <Col md={3} xs={6}>
                  <LinkContainer to='/reports' style={{ cursor: 'pointer' }}>
                    <Card className='text-center py-4'>
                      <Card.Body>
                        <h1>{totalReports}</h1>
                        <h6>{totalReports > 1 ? 'Reports' : 'Report'}</h6>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>
                <Col md={3} xs={6}>
                  <LinkContainer to='/checks' style={{ cursor: 'pointer' }}>
                    <Card className='text-center py-4'>
                      <Card.Body>
                        <h1>{totalChecks}</h1>
                        <h6>
                          {totalChecks > 1
                            ? 'Checks'
                            : 'Check'}
                        </h6>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>
              </>
            ) : (
              <>
                <Col md={4} xs={6}>
                  <LinkContainer to='/reports' style={{ cursor: 'pointer' }}>
                    <Card className='text-center py-4'>
                      <Card.Body>
                        <h1>
                          {myTotalReports}
                        </h1>
                        <h6>
                          {myTotalReports > 1
                            ? 'Reports'
                            : 'Report'}
                        </h6>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>
                <Col md={4} xs={6}>
                  <LinkContainer to='/checks' style={{ cursor: 'pointer' }}>
                    <Card className='text-center py-4'>
                      <Card.Body>
                        <h1>
                          {myTotalChecks}
                        </h1>
                        <h6>
                          {myTotalChecks > 1
                            ? 'Checks'
                            : 'Check'}
                        </h6>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>
              </>
            )}
          </Row>

          <Form onSubmit={updateUserHandler}>
            <h1 className='my-5'>My Profile</h1>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Name</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder='Enter Name'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder='Enter Email'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Phonenumber</FormLabel>
                  <FormControl
                    type='number'
                    onChange={(e) => setPhonenumber(e.target.value)}
                    value={phonenumber}
                    placeholder='Enter Phonenumber'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
            </Row>
            <Row className='my-5'>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Company</FormLabel>
                  <FormControl
                    type='text'
                    value={user && user.company}
                    placeholder='Enter Company'
                    required
                    disabled
                  ></FormControl>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <FormLabel>Office Address</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setOfficeAddress(e.target.value)}
                    value={officeAddress}
                    placeholder='Enter Office Address'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
            </Row>
            <h4 className='py-4'>Change Password</h4>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    type='password'
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder='Enter Password'
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl
                    type='password'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    placeholder='Confirm Password'
                  ></FormControl>
                </FormGroup>
              </Col>

              <Col md={4}>
                <Button
                  className='mt-4 btn-block'
                  variant='warning'
                  type='submit'
                >
                  Update Account
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default MainArea;
