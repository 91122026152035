export const REPORT_LIST_REQUEST = "REPORT_LIST_REQUEST";
export const REPORT_LIST_SUCCESS = "REPORT_LIST_SUCCESS";
export const REPORT_LIST_FAIL = "REPORT_LIST_FAIL";

export const MYREPORT_LIST_REQUEST = "MYREPORT_LIST_REQUEST";
export const MYREPORT_LIST_SUCCESS = "MYREPORT_LIST_SUCCESS";
export const MYREPORT_LIST_FAIL = "MYREPORT_LIST_FAIL";


export const REPORT_DETAILS_REQUEST = "REPORT_DETAILS_REQUEST";
export const REPORT_DETAILS_SUCCESS = "REPORT_DETAILS_SUCCESS";
export const REPORT_DETAILS_FAIL = "REPORT_DETAILS_FAIL";
export const REPORT_DETAILS_RESET = "REPORT_DETAILS_RESET";

export const REPORT_DELETE_REQUEST = "REPORT_DELETE_REQUEST";
export const REPORT_DELETE_SUCCESS = "REPORT_DELETE_SUCCESS";
export const REPORT_DELETE_FAIL = "REPORT_DELETE_FAIL";

export const REPORT_CREATE_REQUEST = "REPORT_CREATE_REQUEST";
export const REPORT_CREATE_SUCCESS = "REPORT_CREATE_SUCCESS";
export const REPORT_CREATE_FAIL = "REPORT_CREATE_FAIL";
export const REPORT_CREATE_RESET = "REPORT_CREATE_RESET";

export const REPORT_UPDATE_REQUEST = "REPORT_UPDATE_REQUEST";
export const REPORT_UPDATE_SUCCESS = "REPORT_UPDATE_SUCCESS";
export const REPORT_UPDATE_FAIL = "REPORT_UPDATE_FAIL";
export const REPORT_UPDATE_RESET = "REPORT_UPDATE_RESET";
