import React, { useEffect } from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  createReport,
  deleteReport,
  listMyReports,
  listReports,
  resetCreateReport,
} from '../actions/reportActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { getUserDetails } from '../actions/userActions';
import ReportSearchBox from '../components/ReportSearchBox';
import PaginateReport from '../components/PaginateReport';

const ReportListScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const keyword = useParams().keyword;
  const pageNumber = useParams().pageNumber || 1;

  const { userInfo } = useSelector((state) => state.userLogin);

  const {
    success: successDelete,
    error: errorDelete,
    loading: loadingDelete,
  } = useSelector((state) => state.reportDelete);

  const {
    success: successCreate,
    error: errorCreate,
    loading: loadingCreate,
    report: newReport,
  } = useSelector((state) => state.reportCreate);

  const { loading, error, reports, page, pages } = useSelector(
    (state) => state.reportList
  );

  const { myLoading, myReports, myPage, myPages } = useSelector(
    (state) => state.myReportList
  );

  // const { user } = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login")
    }
    else {
      dispatch(getUserDetails('profile'));
      dispatch(listMyReports(keyword, pageNumber))

      if (userInfo.isAdmin) {
        dispatch(listReports(keyword, pageNumber))
      }
    }



    if (successCreate) {
      dispatch(resetCreateReport());
      navigate(`/report/${newReport._id}/edit`);
    }
  }, [
    dispatch,
    navigate,
    userInfo,
    successDelete,
    successCreate,
    newReport,
    pageNumber,
    keyword,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteReport(id));
    }
  };

  const createReportHandler = () => {
    dispatch(createReport());
  };

  const downloadFile = (url) => {
    const filename = url.split('/').pop();
    const aTag = document.createElement('a');
    aTag.href = url;
    aTag.setAttribute('download', filename);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();

    //console.log(url.slice(9));
  };

  return (
    <>
      <Link to='/' className='btn btn-info my-3'>
        Go Back
      </Link>

      <Row>
        <Col>
          <h1>Reports</h1>
        </Col>
        {userInfo.isAdmin && (
          <>
            <Col md={6}>
              <ReportSearchBox />
            </Col>
            <Col className='text-right'>
              <Button
                variant='warning'
                type='button'
                onClick={createReportHandler}
              >
                <i className='fas fa-plus' /> Create Report
              </Button>
            </Col>
          </>
        )}
      </Row>
      {loadingCreate && <Loader />}
      {errorCreate && <Message>{errorCreate}</Message>}
      {loadingDelete && <Loader />}
      {errorDelete && <Message>{errorDelete}</Message>}
      {(loading | myLoading) ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table bordered striped hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>TITLE</th>

                {userInfo.isAdmin && <th>COMPANY</th>}
                <th>FILE</th>
                <th>UPDATED AT</th>

                {userInfo.isAdmin && <th></th>}
              </tr>
            </thead>
            <tbody>
              {userInfo.isAdmin
                ? reports.map((report) => (
                  <tr key={report._id}>
                    <td>{report._id}</td>
                    <td>{report.title}</td>

                    <td>{report.company}</td>
                    <td>
                      <Button
                        className='btn-sm'
                        variant='warning'
                        onClick={() =>
                          downloadFile(
                            `${window.location.origin
                            }/uploads/${report.image.slice(9)}`
                          )
                        }
                      >
                        <i className='fas fa-download' />
                      </Button>
                    </td>
                    <td>{report.createdAt.substring(0, 10)}</td>

                    <td>
                      <LinkContainer to={`/report/${report._id}/edit`}>
                        <Button className='btn-sm'>
                          <i className='fas fa-edit' />
                        </Button>
                      </LinkContainer>
                      <Button
                        className='btn-sm'
                        variant='danger'
                        onClick={() => deleteHandler(report._id)}
                      >
                        <i className='fas fa-trash' />
                      </Button>
                    </td>
                  </tr>
                ))
                : myReports?.map((report) => (
                  <tr key={report._id}>
                    <td>{report._id}</td>
                    <td>{report.title}</td>

                    <td>
                      <Button
                        className='btn-sm'
                        variant='success'
                        onClick={() =>
                          downloadFile(
                            `${window.location.origin
                            }/uploads/${report.image.slice(9)}`
                          )
                        }
                      >
                        Download
                      </Button>
                    </td>
                    <td>{report.createdAt.substring(0, 10)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {userInfo.isAdmin && (
            <PaginateReport pages={userInfo.isAdmin ? pages : myPages} page={userInfo.isAdmin ? page : myPage} keyword={keyword} />
          )}
        </>
      )}
    </>
  );
};

export default ReportListScreen;
