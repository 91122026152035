import { configureStore } from "@reduxjs/toolkit";
import {
  myReportListReducer,
  reportCreateReducer,
  reportDeleteReducer,
  reportDetailsReducer,
  reportListReducer,
  reportUpdateReducer,
} from "./reducer/reportReducer";

import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userProfileUpdateReducer,
  userRegisterReducer,
  userUpdateReducer,
  userVerifyReducer,
} from "./reducer/userReducer";
import {
  resultBulkCreateReducer,
  resultCreateReducer,
  resultDeleteReducer,
  resultDetailsReducer,
  resultListReducer,
  resultUpdateReducer,
} from "./reducer/resultReducer";

import {
  checkBulkCreateReducer,
  checkCompleteReducer,
  checkCreateReducer,
  checkDeleteReducer,
  checkDetailsReducer,
  checkFormReducer,
  checkListReducer,
  checkPayReducer,
  myCheckListReducer,
} from "./reducer/checkReducer";

const localStorageUserInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const localStoragePersonalInfo = localStorage.getItem("personalDetails")
  ? JSON.parse(localStorage.getItem("personalDetails"))
  : {};

const localStorageGuarantorInfo = localStorage.getItem("guarantorDetails")
  ? JSON.parse(localStorage.getItem("guarantorDetails"))
  : {};

const localStorageRefereeInfo = localStorage.getItem("refereeDetails")
  ? JSON.parse(localStorage.getItem("refereeDetails"))
  : {};

const localStoragePrevempInfo = localStorage.getItem("prevempDetails")
  ? JSON.parse(localStorage.getItem("prevempDetails"))
  : {};

const preloadedState = {
  userLogin: {
    userInfo: localStorageUserInfo,
  },
  checkForm: {
    personalDetails: localStoragePersonalInfo,
    guarantorDetails: localStorageGuarantorInfo,
    refereeDetails: localStorageRefereeInfo,
    prevempDetails: localStoragePrevempInfo,
  },
};

const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userList: userListReducer,
    userDetails: userDetailsReducer,
    userProfileUpdate: userProfileUpdateReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userVerify: userVerifyReducer,
    reportList: reportListReducer,
    myReportList: myReportListReducer,
    reportUpdate: reportUpdateReducer,
    reportCreate: reportCreateReducer,
    reportDelete: reportDeleteReducer,
    reportDetails: reportDetailsReducer,
    resultList: resultListReducer,
    resultCreate: resultCreateReducer,
    resultBulkCreate: resultBulkCreateReducer,
    resultDelete: resultDeleteReducer,
    resultDetails: resultDetailsReducer,
    resultUpdate: resultUpdateReducer,
    checkCreate: checkCreateReducer,
    checkList: checkListReducer,
    myCheckList: myCheckListReducer,
    checkDetails: checkDetailsReducer,
    checkDelete: checkDeleteReducer,
    checkForm: checkFormReducer,
    checkBulkCreate: checkBulkCreateReducer,
    checkPay: checkPayReducer,
    checkComplete: checkCompleteReducer
  },
  preloadedState,
});

export default store;
