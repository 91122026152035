import axios from 'axios';

import { logout } from './userActions';
import {
  CHECK_BULK_CREATE_FAIL,
  CHECK_BULK_CREATE_REQUEST,
  CHECK_BULK_CREATE_RESET,
  CHECK_BULK_CREATE_SUCCESS,
  CHECK_COMPLETE_FAIL,
  CHECK_COMPLETE_REQUEST,
  CHECK_COMPLETE_SUCCESS,
  CHECK_CREATE_FAIL,
  CHECK_CREATE_REQUEST,
  CHECK_CREATE_RESET,
  CHECK_CREATE_SUCCESS,
  CHECK_DELETE_FAIL,
  CHECK_DELETE_REQUEST,
  CHECK_DELETE_SUCCESS,
  CHECK_DETAILS_FAIL,
  CHECK_DETAILS_REQUEST,
  CHECK_DETAILS_SUCCESS,
  CHECK_FORM_RESET,
  CHECK_LIST_FAIL,
  CHECK_LIST_REQUEST,
  CHECK_LIST_SUCCESS,
  CHECK_PAY_FAIL,
  CHECK_PAY_REQUEST,
  CHECK_PAY_SUCCESS,
  MYCHECK_LIST_FAIL,
  MYCHECK_LIST_REQUEST,
  MYCHECK_LIST_SUCCESS,
  SAVE_GUARANTOR_DETAILS,
  SAVE_PERSONAL_DETAILS,
  SAVE_PREVEMP_DETAILS,
  SAVE_REFEREE_DETAILS,
} from '../types/checkTypes';

export const createCheck =
  (personalDetails, guarantorDetails, refereeDetails, prevempDetails) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: CHECK_CREATE_REQUEST,
        });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.post(
          `/api/checks/`,
          {
            personalDetails,
            guarantorDetails,
            refereeDetails,
            prevempDetails,
          },
          config
        );

        dispatch({
          type: CHECK_CREATE_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === 'Not authorized, Token failed') {
          dispatch(logout());
        }

        dispatch({
          type: CHECK_CREATE_FAIL,
          payload: message,
        });
      }
    };

export const bulkCreateCheck = (checks, type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECK_BULK_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/checks/bulk`, { checks, type }, config);

    dispatch({
      type: CHECK_BULK_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: CHECK_BULK_CREATE_FAIL,
      payload: message,
    });
  }
};


export const listChecks =
  (page = 1, limit = 10) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: CHECK_LIST_REQUEST,
        });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.get(
          `/api/checks?page=${page}&limit=${limit}`,
          config
        );

        dispatch({
          type: CHECK_LIST_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === 'Not authorized, Token failed') {
          dispatch(logout());
        }
        dispatch({
          type: CHECK_LIST_FAIL,
          payload: message,
        });
      }
    };


export const listMyChecks =
  (page = 1, limit = 10) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: MYCHECK_LIST_REQUEST,
        });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.get(
          `/api/checks/mine?page=${page}&limit=${limit}`,
          config
        );

        dispatch({
          type: MYCHECK_LIST_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === 'Not authorized, Token failed') {
          dispatch(logout());
        }
        dispatch({
          type: MYCHECK_LIST_FAIL,
          payload: message,
        });
      }
    };


export const getCheckDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECK_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const res = await axios.get(`/api/checks/${id}`, config);

    dispatch({
      type: CHECK_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, Token failed') {
      dispatch(logout());
    }

    dispatch({
      type: CHECK_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const deleteCheck = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECK_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/checks/${id}`, config);

    dispatch({
      type: CHECK_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: CHECK_DELETE_FAIL,
      payload: message,
    });
  }
};


export const payCheck = (check) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECK_PAY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };


    const { data } = await axios.put(
      `/api/checks/${check._id}/pay`, {}, config
    )

    dispatch({
      type: CHECK_PAY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CHECK_PAY_FAIL,
      payload: message,
    })
  }
}

export const completeCheck = (check) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHECK_COMPLETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/checks/${check._id}/complete`,
      {},
      config
    )

    dispatch({
      type: CHECK_COMPLETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: CHECK_COMPLETE_FAIL,
      payload: message,
    })
  }
}


export const savePersonalDetails = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PERSONAL_DETAILS,
    payload: data,
  });

  localStorage.setItem('personalDetails', JSON.stringify(data));
};

export const saveGuarantorDetails = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GUARANTOR_DETAILS,
    payload: data,
  });

  localStorage.setItem('guarantorDetails', JSON.stringify(data));
};

export const saveRefereeDetails = (data) => (dispatch) => {
  dispatch({
    type: SAVE_REFEREE_DETAILS,
    payload: data,
  });

  localStorage.setItem('refereeDetails', JSON.stringify(data));
};

export const savePrevempDetails = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PREVEMP_DETAILS,
    payload: data,
  });

  localStorage.setItem('prevempDetails', JSON.stringify(data));
};

export const checkFormReset = () => (dispatch) => {
  dispatch({
    type: CHECK_FORM_RESET,
  });

  localStorage.removeItem('personalDetails');
  localStorage.removeItem('guarantorDetails');
  localStorage.removeItem('refereeDetails');
  localStorage.removeItem('prevempDetails');
};

export const checkCreateReset = () => (dispatch) => {
  dispatch({
    type: CHECK_CREATE_RESET,
  });
};

export const resetBulkCreateCheck = () => (dispatch) =>
  dispatch({ type: CHECK_BULK_CREATE_RESET });

