export const CHECK_CREATE_REQUEST = "CHECK_CREATE_REQUEST";
export const CHECK_CREATE_SUCCESS = "CHECK_CREATE_SUCCESS";
export const CHECK_CREATE_FAIL = "CHECK_CREATE_FAIL";
export const CHECK_CREATE_RESET = "CHECK_CREATE_RESET";

export const CHECK_BULK_CREATE_REQUEST = 'CHECK_BULK_CREATE_REQUEST';
export const CHECK_BULK_CREATE_SUCCESS = 'CHECK_BULK_CREATE_SUCCESS';
export const CHECK_BULK_CREATE_FAIL = 'CHECK_BULK_CREATE_FAIL';
export const CHECK_BULK_CREATE_RESET = 'CHECK_BULK_CREATE_RESET';

export const CHECK_LIST_REQUEST = "CHECK_LIST_REQUEST";
export const CHECK_LIST_SUCCESS = "CHECK_LIST_SUCCESS";
export const CHECK_LIST_FAIL = "CHECK_LIST_FAIL";
export const CHECK_LIST_RESET = "CHECK_LIST_RESET";

export const MYCHECK_LIST_REQUEST = "MYCHECK_LIST_REQUEST";
export const MYCHECK_LIST_SUCCESS = "MYCHECK_LIST_SUCCESS";
export const MYCHECK_LIST_FAIL = "MYCHECK_LIST_FAIL";
export const MYCHECK_LIST_RESET = "MYCHECK_LIST_RESET";

export const CHECK_DETAILS_REQUEST = "CHECK_DETAILS_REQUEST";
export const CHECK_DETAILS_SUCCESS = "CHECK_DETAILS_SUCCESS";
export const CHECK_DETAILS_FAIL = "CHECK_DETAILS_FAIL";
export const CHECK_DETAILS_RESET = "CHECK_DETAILS_RESET";

export const CHECK_DELETE_REQUEST = "CHECK_DELETE_REQUEST";
export const CHECK_DELETE_SUCCESS = "CHECK_DELETE_SUCCESS";
export const CHECK_DELETE_FAIL = "CHECK_DELETE_FAIL";

export const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS";
export const SAVE_GUARANTOR_DETAILS = "SAVE_GUARANTOR_DETAILS";
export const SAVE_REFEREE_DETAILS = "SAVE_REFEREE_DETAILS";
export const SAVE_PREVEMP_DETAILS = "SAVE_PREVEMP_DETAILS";

export const CHECK_PAY_REQUEST = 'CHECK_PAY_REQUEST'
export const CHECK_PAY_SUCCESS = 'CHECK_PAY_SUCCESS'
export const CHECK_PAY_FAIL = 'CHECK_PAY_FAIL'
export const CHECK_PAY_RESET = 'CHECK_PAY_RESET'

export const CHECK_COMPLETE_REQUEST = 'CHECK_COMPLETE_REQUEST'
export const CHECK_COMPLETE_SUCCESS = 'CHECK_COMPLETE_SUCCESS'
export const CHECK_COMPLETE_FAIL = 'CHECK_COMPLETE_FAIL'
export const CHECK_COMPLETE_RESET = 'CHECK_COMPLETE_RESET'

export const CHECK_FORM_RESET = "CHECK_FORM_RESET";
