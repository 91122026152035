import { useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
} from "react-bootstrap";
import { isEmpty, isEmail } from "./helper/validate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormContainer from "./FormContainer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // check fields
    if (isEmpty(email))
      return toast("Please fill in all fields.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check email
    if (!isEmail(email))
      return toast("Please enter a valid email address.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    try {
      await axios.post("/api/users/forgot-password", { email });
      setEmail("");
      return toast("Please check your email 📧", {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
    } catch (err) {
      toast(err.response.data.msg, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <FormContainer>
        <h1 className="my-5">RESET PASSWORD</h1>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Email Address</FormLabel>
            <FormControl
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Enter Email"
            ></FormControl>
          </FormGroup>
          <Button type="submit" className="my-4 btn-warning">
            Submit Request
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default ForgotPassword;
