import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";

const AddCheckSteps = ({ step1, step2, step3, step4 }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  return (
    <Nav className="justify-content-center mb-4">
      <Nav.Item>
        {step1 ? (
          <LinkContainer to="/personal">
            <Nav.Link>Personal</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Personal</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/guarantor">
            <Nav.Link>Guarantor</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Guarantor</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/referee">
            <Nav.Link>Referee</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Referee</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/prevemp">
            <Nav.Link>Previous</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Previous</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
};

export default AddCheckSteps;
