import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  FormControl,
  FormLabel,
  FormGroup,
} from "react-bootstrap";
import { register } from "../actions/userActions";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";

const Modalify = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [type, setType] = useState("");

  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { error } = userRegister;

  const registerHandler = (e) => {
    e.preventDefault();
    props.onHide();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else if (type === "") {
      setMessage("Please select an option");
    } else {
      dispatch(
        register(
          name,
          email,
          password,
          company,
          phonenumber,
          officeAddress,
          type
        )
      );
    }

    setName("");
    setEmail("");
    setOfficeAddress("");
    setPassword("");
    setPhonenumber("");
    setType("");
    setCompany("");

    props.onCreate();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={registerHandler}>
        {message && <Message variant="warning">{message}</Message>}
        {error && <Message variant="danger">{error}</Message>}
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add User Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Enter Name"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Email Address</FormLabel>
                <FormControl
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Enter Email"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Company</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                  placeholder="Enter Company"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>Password</FormLabel>
                <FormControl
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Enter Password"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>Confirm Password</FormLabel>
                <FormControl
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  required
                ></FormControl>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup className="py-3">
                <FormLabel>Role/Relationship</FormLabel>
                <FormControl
                  as="select"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                  required
                >
                  <option value="">Select an Option</option>
                  <option value="Employee">Employee</option>
                  <option value="Client">Client</option>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup className="py-3">
                <FormLabel>Phonenumber</FormLabel>
                <FormControl
                  type="number"
                  onChange={(e) => setPhonenumber(e.target.value)}
                  value={phonenumber}
                  placeholder="Enter Phonenumber"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>Office Address</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setOfficeAddress(e.target.value)}
                  value={officeAddress}
                  placeholder="Enter Office Address"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" type="submit">
            Add User
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Modalify;
