import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  Form,
  Button,
  FormGroup,
  FormCheck,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import {
  getUserDetails,
  updateUser,
  userUpdateReset,
} from "../actions/userActions";

const UserEditScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isAccountManager, setIsAccountManager] = useState(false);
  const [phonenumber, setPhonenumber] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [assignedTo, setAssignedTo] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success } = userUpdate;
  const {users, loading: loadingUsers} = useSelector((state) => state.userList);

  useEffect(() => {
    if (success) {
      dispatch(userUpdateReset());
      navigate("/users");
    } else {
      if (!user.name || id !== user._id) {
        dispatch(getUserDetails(id));
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
        setOfficeAddress(user.officeAddress);
        setPhonenumber(user.phonenumber);
        setIsAccountManager(user.isAccountManager);
        setVerified(user.verified);
        setAssignedTo(user.assignedTo);
      }
    }
  }, [dispatch, navigate, id, success, user]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateUser({
        _id: id,
        name,
        email,
        isAdmin,
        verified,
        phonenumber,
        officeAddress,
        isAccountManager,
        assignedTo
      })
    );
  };

  return (
    <>
      <Link to="/users" className="btn btn-info my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId="name">
              <FormLabel>Name</FormLabel>
              <FormControl
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Name"
              ></FormControl>
            </FormGroup>

            <FormGroup className="py-3" controlId="email">
              <FormLabel>Email Address</FormLabel>
              <FormControl
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Enter Email"
              ></FormControl>
            </FormGroup>

            <FormGroup className="py-3" controlId="phone">
              <FormLabel>Phonenumber</FormLabel>
              <FormControl
                type="number"
                onChange={(e) => setPhonenumber(e.target.value)}
                value={phonenumber}
                placeholder="Enter Phonenumber"
                required
              ></FormControl>
            </FormGroup>

           

            <FormGroup className="py-3" controlId="office">
              <FormLabel>Office Address</FormLabel>
              <FormControl
                type="text"
                onChange={(e) => setOfficeAddress(e.target.value)}
                value={officeAddress}
                placeholder="Enter Office Address"
                required
              ></FormControl>
            </FormGroup>

            <FormGroup className="py-3" controlId="verified">
              <FormCheck
                type="checkbox"
                label="verified"
                checked={verified}
                onChange={(e) => setVerified(e.target.checked)}
              ></FormCheck>
            </FormGroup>

           {user.type==='Employee' && (
            <>
          <FormGroup className="py-3" controlId="accountManager">
          <FormCheck
            type="checkbox"
            label="accountManager"
            checked={isAccountManager}
            onChange={(e) => setIsAccountManager(e.target.checked)}
          ></FormCheck>
        </FormGroup>
         <FormGroup className="py-3" controlId="isAdmin">
         <FormCheck
           type="checkbox"
           label="isAdmin"
           checked={isAdmin}
           onChange={(e) => setIsAdmin(e.target.checked)}
         ></FormCheck>
       </FormGroup>
       </>
           )}
            


         {user.type==='Client'  && (
          <FormGroup className="py-3" controlId="assignedTo">
          <FormLabel>Account Manager</FormLabel>
          <FormControl
              as="select"
              onChange={(e) => setAssignedTo(e.target.value)}
              value={assignedTo}
              
            >
              {!loadingUsers && users.filter(user=> user.isAccountManager).map(user =>(
                <option value={user.name}>{user.name}</option>
              ))}
            </FormControl>
          </FormGroup>
         ) }
           

            <Button type="submit" variant="warning" className="mt-4">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default UserEditScreen;
