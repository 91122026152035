import axios from 'axios';
import {
  MYREPORT_LIST_FAIL,
  MYREPORT_LIST_REQUEST,
  MYREPORT_LIST_SUCCESS,
  REPORT_CREATE_FAIL,
  REPORT_CREATE_REQUEST,
  REPORT_CREATE_RESET,
  REPORT_CREATE_SUCCESS,
  REPORT_DELETE_FAIL,
  REPORT_DELETE_REQUEST,
  REPORT_DELETE_SUCCESS,
  REPORT_DETAILS_FAIL,
  REPORT_DETAILS_REQUEST,
  REPORT_DETAILS_RESET,
  REPORT_DETAILS_SUCCESS,
  REPORT_LIST_FAIL,
  REPORT_LIST_REQUEST,
  REPORT_LIST_SUCCESS,
  REPORT_UPDATE_FAIL,
  REPORT_UPDATE_REQUEST,
  REPORT_UPDATE_RESET,
  REPORT_UPDATE_SUCCESS,
} from '../types/reportTypes';
import { logout } from './userActions';

export const listReports =
  (keyword = '', page = 1, limit = 10) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: REPORT_LIST_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.get(
          `/api/reports?keyword=${keyword}&page=${page}&limit=${limit}`,
          config
        );

        dispatch({
          type: REPORT_LIST_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === 'Not authorized, Token failed') {
          dispatch(logout());
        }
        dispatch({
          type: REPORT_LIST_FAIL,
          payload: message,
        });
      }
    };


export const listMyReports =
  (keyword = '', page = 1, limit = 10) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: MYREPORT_LIST_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        const { data } = await axios.get(
          `/api/reports/mine?keyword=${keyword}&page=${page}&limit=${limit}`,
          config
        );

        dispatch({
          type: MYREPORT_LIST_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === 'Not authorized, Token failed') {
          dispatch(logout());
        }
        dispatch({
          type: MYREPORT_LIST_FAIL,
          payload: message,
        });
      }
    };

export const getReportDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: REPORT_DETAILS_REQUEST });

    const res = await axios.get(`/api/reports/${id}`);
    dispatch({
      type: REPORT_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === 'Not authorized, Token failed') {
      dispatch(logout());
    }
    dispatch({
      type: REPORT_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const deleteReport = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REPORT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/reports/${id}`, config);

    dispatch({
      type: REPORT_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: REPORT_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createReport = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: REPORT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/reports/`, {}, config);

    dispatch({
      type: REPORT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: REPORT_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateReport = (report) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REPORT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/reports/${report._id}`,
      report,
      config
    );

    dispatch({
      type: REPORT_UPDATE_SUCCESS,
    });

    dispatch({
      type: REPORT_DETAILS_SUCCESS,
      payload: data,
    });

    dispatch({ type: REPORT_DETAILS_RESET });
  } catch (error) {
    dispatch({
      type: REPORT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetCreateReport = () => (dispatch) =>
  dispatch({ type: REPORT_CREATE_RESET });

export const resetUpdateReport = () => (dispatch) =>
  dispatch({ type: REPORT_UPDATE_RESET });
