import React, { useEffect, useState } from "react";
import FormContainer from "../components/FormContainer";
import AddCheckSteps from "../components/AddCheckSteps";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { checkFormReset, savePersonalDetails } from "../actions/checkActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";

const PersonalScreen = () => {
  const checkForm = useSelector((state) => state.checkForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [certificate, setCertificate] = useState(
    checkForm.personalDetails ? checkForm.personalDetails.certificate : ""
  );
  const [name, setName] = useState(
    checkForm.personalDetails ? checkForm.personalDetails.name : ""
  );
  const [address, setAddress] = useState(
    checkForm.personalDetails ? checkForm.personalDetails.address : ""
  );
  const [institution, setInstitution] = useState(
    checkForm.personalDetails ? checkForm.personalDetails.institution : ""
  );
  const [matricNumber, setMatricNumber] = useState(
    checkForm.personalDetails ? checkForm.personalDetails.matricNumber : ""
  );

  const [uploading, setUploading] = useState(false);

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [navigate, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      savePersonalDetails({
        name,
        address,
        institution,
        certificate,
        matricNumber,
      })
    );
    navigate("/guarantor");
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("check", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload/check", formData, config);

      setCertificate(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const handleCancel = () => {
    dispatch(checkFormReset());
  };

  return (
    <FormContainer>
      <AddCheckSteps step1 />
      <h3>Personal Details</h3>
      <Form onSubmit={submitHandler}>
        <FormGroup className="py-3">
          <FormLabel>Name</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter Name"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Address</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder="Enter Address"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Institution</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setInstitution(e.target.value)}
            value={institution}
            placeholder="Enter Institution"
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Attach Copy of Certificate </FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setCertificate(e.target.value)}
            value={certificate}
            placeholder="Enter File URL"
          ></FormControl>
          <FormControl
            type="file"
            label="Choose file"
            onChange={uploadFileHandler}
          ></FormControl>
          {uploading && <Loader />}
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Matric Number</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setMatricNumber(e.target.value)}
            value={matricNumber}
            placeholder="Enter Matric Number"
          ></FormControl>
        </FormGroup>
        <Row className="mt-3">
          <Col>
            <LinkContainer to="/checks">
              <Button className="btn-sm" onClick={handleCancel}>
                Cancel
              </Button>
            </LinkContainer>
          </Col>
          <Col className="text-right">
            <Button type="submit" className="btn-info btn-sm">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

export default PersonalScreen;
