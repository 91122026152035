import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import SearchBox from './SearchBox';
import { logout } from '../actions/userActions';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <header>
      <Navbar
        bg='warning'
        variant='dark'
        expand='lg'
        collapseOnSelect
        className='navbar'
      >
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>3VAP</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            {userInfo && userInfo.isAdmin && userInfo.verified && <SearchBox />}
            <Nav className='ml-auto'>
              {userInfo && userInfo.verified ? (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && userInfo.verified && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/users'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/reports'>
                    <NavDropdown.Item>Reports</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/results'>
                    <NavDropdown.Item>Results</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/checks'>
                    <NavDropdown.Item>Checks</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
