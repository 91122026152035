import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  FormControl,
  FormLabel,
  FormGroup,
} from "react-bootstrap";
import { createResult } from "../actions/resultActions";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";

const AddResultModal = (props) => {
  const [fullName, setFullName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [grade, setGrade] = useState("");
  const [matricNumber, setMatricNumber] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [school, setSchool] = useState("");

  // const [message, setMessage] = useState(null);
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.resultCreate);

  const submitHandler = (e) => {
    e.preventDefault();
    props.onHide();

    dispatch(
      createResult(
        fullName,
        courseTitle,
        grade,
        matricNumber,
        graduationYear,
        school
      )
    );

    setFullName("");
    setCourseTitle("");
    setGrade("");
    setMatricNumber("");
    setGraduationYear("");
    setSchool("");

    props.onCreate();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={submitHandler}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Result Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Message variant="danger">{error}</Message>}

          <Row>
            <Col>
              <FormGroup>
                <FormLabel>Full Name</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setFullName(e.target.value)}
                  value={fullName}
                  placeholder="Enter Full Name"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Course Title</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setCourseTitle(e.target.value)}
                  value={courseTitle}
                  placeholder="Enter Course Title"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>Grade</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setGrade(e.target.value)}
                  value={grade}
                  placeholder="Enter Grade"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>Matric Number</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setMatricNumber(e.target.value)}
                  value={matricNumber}
                  placeholder="Enter Matric Number"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>Graduation Year</FormLabel>
                <FormControl
                  type="number"
                  onChange={(e) => setGraduationYear(e.target.value)}
                  value={graduationYear}
                  placeholder="Enter Graduation Year"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="py-3">
                <FormLabel>School</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setSchool(e.target.value)}
                  value={school}
                  placeholder="Enter School"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" type="submit">
            Add Result
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddResultModal;
