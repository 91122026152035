import {
  RESULT_BULK_CREATE_FAIL,
  RESULT_BULK_CREATE_REQUEST,
  RESULT_BULK_CREATE_RESET,
  RESULT_BULK_CREATE_SUCCESS,
  RESULT_CREATE_FAIL,
  RESULT_CREATE_REQUEST,
  RESULT_CREATE_SUCCESS,
  RESULT_DETAILS_FAIL,
  RESULT_DETAILS_REQUEST,
  RESULT_DETAILS_SUCCESS,
  RESULT_LIST_FAIL,
  RESULT_LIST_REQUEST,
  RESULT_LIST_SUCCESS,
  RESULT_CREATE_RESET,
  RESULT_DETAILS_RESET,
  RESULT_DELETE_FAIL,
  RESULT_DELETE_SUCCESS,
  RESULT_DELETE_REQUEST,
  RESULT_UPDATE_RESET,
  RESULT_UPDATE_FAIL,
  RESULT_UPDATE_SUCCESS,
  RESULT_UPDATE_REQUEST,
} from '../types/resultTypes';

export const resultListReducer = (state = { results: [] }, action) => {
  switch (action.type) {
    case RESULT_LIST_REQUEST:
      return { loading: true, results: [] };
    case RESULT_LIST_SUCCESS:
      return {
        loading: false,
        results: action.payload.results,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case RESULT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const resultDetailsReducer = (state = { result: {} }, action) => {
  switch (action.type) {
    case RESULT_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case RESULT_DETAILS_SUCCESS:
      return {
        loading: false,
        result: action.payload,
      };
    case RESULT_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case RESULT_DETAILS_RESET:
      return {
        result: {},
      };

    default:
      return state;
  }
};

export const resultCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case RESULT_CREATE_REQUEST:
      return { loading: true };
    case RESULT_CREATE_SUCCESS:
      return { loading: false, success: true, result: action.payload };
    case RESULT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case RESULT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const resultBulkCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case RESULT_BULK_CREATE_REQUEST:
      return { loading: true };
    case RESULT_BULK_CREATE_SUCCESS:
      return { loading: false, success: true, result: action.payload };
    case RESULT_BULK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case RESULT_BULK_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const resultDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case RESULT_DELETE_REQUEST:
      return { loading: true };
    case RESULT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case RESULT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const resultUpdateReducer = (state = { result: {} }, action) => {
  switch (action.type) {
    case RESULT_UPDATE_REQUEST:
      return { loading: true };
    case RESULT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case RESULT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case RESULT_UPDATE_RESET:
      return {
        result: {},
      };

    default:
      return state;
  }
};
