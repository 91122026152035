import React, { useEffect, useRef, useState } from 'react';

import { Button, Col, Row, Table, FormControl, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { getUserDetails } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import * as xlsx from "xlsx";
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  checkFormReset,
  deleteCheck,
  listChecks,
  bulkCreateCheck,
  resetBulkCreateCheck,
  listMyChecks
} from '../actions/checkActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import CheckPaginate from '../components/CheckPaginate';

const CheckListScreen = () => {
  const [type, setType] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageNumber = useParams().pageNumber || 1;
  const [excelRows, setExcelRows] = useState([]);
  const inputRef = useRef();

  const { userInfo } = useSelector((state) => state.userLogin);

  const { success, error: errorBulkCreate } = useSelector((state) => state.checkBulkCreate);


  const { loading, error, checks, page, pages } = useSelector(
    (state) => state.checkList
  );
  const { myChecks, myPage, myPages, myLoading } = useSelector(
    (state) => state.myCheckList
  );
  const {
    loading: loadingDelete,
    success: successDelete,
    error: errorDelete,
  } = useSelector((state) => state.checkDelete);

  const { user } = useSelector((state) => state.userDetails);

  const duration = moment().add(-7, 'd');

  useEffect(() => {
    if (!userInfo) {
      navigate("/login")
    }
    else {
      dispatch(getUserDetails('profile'));

      dispatch(listMyChecks(pageNumber));
      dispatch(checkFormReset());

      if (userInfo.isAdmin) {

        dispatch(listChecks(pageNumber));

      }
    }

    if (success) {
      dispatch(resetBulkCreateCheck());
    }
  }, [dispatch, navigate, userInfo, success, successDelete, pageNumber]);

  // const downloadFile = (url) => {
  //   const filename = url.split('/').pop();
  //   const aTag = document.createElement('a');
  //   aTag.href = url;
  //   aTag.setAttribute('download', filename);
  //   document.body.appendChild(aTag);
  //   aTag.click();
  //   aTag.remove();

  //   //console.log(url.slice(9));
  // };

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteCheck(id));
    }
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const fileObj = e.target.files;

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        console.log(json);
        setExcelRows(json);
      };
      reader.readAsArrayBuffer(fileObj[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(bulkCreateCheck(excelRows, type));
    inputRef.current.value = "";
  };


  return (
    <>
      <Link to='/' className='btn btn-info my-3'>
        Go Back
      </Link>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {errorBulkCreate && <Message variant='danger'>{errorBulkCreate}</Message>}
      <Row>
        <Col>
          <h1>Checks</h1>
        </Col>
        <Col className='text-right pt-3'>
          {/* {userInfo && !userInfo.isAdmin && (
            <LinkContainer to='/personal'>
              <Button className='btn-sm btn-info'>
                <i className='fas fa-check' /> Start a check
              </Button>
            </LinkContainer>
          )} */}
          {userInfo && !userInfo.isAdmin && (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <FormControl
                    as="select"
                    onChange={(e) => setType(e.target.value)}

                    value={type}
                    required
                  >
                    <option value="">Select a Check</option>
                    <option value="Address">Address</option>
                    <option value="Guarantor">Guarantor</option>
                    <option value="Reference">Reference</option>
                    <option value="PreviousEmployer">Previous Employer</option>
                    <option value="DegreeLocal">Degree - Local</option>
                    <option value="DegreeForeign">Degree - Foreign</option>
                    <option value="Tradetest">Tradetest</option>
                    <option value="NYSC">NYSC</option>
                    <option value="Credit">Credit</option>
                    <option value="Identity">Identity</option>
                    <option value="ProfessionalLicense">Professional License</option>
                    <option value="OLevel">O-Level</option>
                    <option value="Others">Others</option>


                  </FormControl>
                </Col>
                <Col>
                  {" "}
                  <FormControl
                    size="md"
                    type="file"
                    label="Choose excel"
                    onChange={readUploadFile}
                    ref={inputRef}
                  ></FormControl>
                </Col>
                <Col>
                  <Button type="submit" className="btn-md btn-warning">
                    Add Checks
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Col>
      </Row>

      {(loading || myLoading) ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table bordered striped hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                {user.isAdmin && <th>CLIENT</th>}
                <th>TYPE</th>
                <th>PAID</th>
                <th>COMPLETED</th>
                {/* <th>FILE</th> */}
                <th>ASSIGNED TO</th>
                <th>CREATED AT</th>
                {userInfo.isAdmin && <th>TIME LEFT</th>}
                {userInfo.isAdmin && <th></th>}
              </tr>
            </thead>
            <tbody>
              {userInfo.isAdmin
                ? checks.map((check) => (
                  <tr key={check._id}>
                    <LinkContainer
                      to={`/check/${check._id}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{check._id.slice(-6)}</td>
                    </LinkContainer>
                    <td>{check.user && check.user.company}</td>
                    <td>{check.type}</td>
                    <td>
                      {check.isPaid ? (
                        check.paidAt.substring(0, 10)
                      ) : (
                        <i className='fas fa-times' style={{ color: 'red' }}></i>
                      )}
                    </td>

                    <td>
                      {check.isCompleted ? (
                        check.completedAt.substring(0, 10)
                      ) : (
                        <i className='fas fa-times' style={{ color: 'red' }}></i>
                      )}
                    </td>

                    {/* <td>
                      {check.type === "Others" && check.Certificate ? (
                        <Link to={check.Certificate}>View File</Link>
                      ) : check.type === "NYSC" && check.NYSCCert ? <Link to={check.NYSCCert}>View File</Link> :
                        check.type === "DegreeLocal" && check.DegreeCert ? <Link to={check.DegreeCert}>View File</Link> :
                          check.type === "DegreeForeign" && check.DegreeCert ? <Link to={check.DegreeCert}>View File</Link> :
                            check.type === "Tradetest" && check.TradetestCert ? <Link to={check.TradetestCert}>View File</Link> :
                              check.type === "OLevel" && check.OLevelCert ? <Link to={check.OLevelCert}>View File</Link> :
                                check.type === "ProfessionalLicense" && check.ProfessionalLicense ? <Link to={check.ProfessionalLicense}>View File</Link> : (
                                  'None'
                                )}
                    </td> */}
                    <td>{check.user && check.user.assignedTo}</td>
                    <td>
                      <time>
                        <Moment format='ddd MMM D YYYY'>
                          {check.createdAt}
                        </Moment>
                      </time>
                    </td>
                    <td>
                      <time
                        style={{
                          color: '#ff0202',

                          fontWeight: 900,
                        }}
                      >
                        <Moment

                          duration={duration}
                          date={check.paidAt && check.paidAt}
                          format='dd:hh:mm'
                        />
                      </time>
                    </td>

                    <td>
                      <LinkContainer to={`/check/${check._id}`}>
                        <Button className='btn-sm btn-success'>
                          <i className='fas fa-eye' />
                        </Button>
                      </LinkContainer>
                      <Button
                        className='btn-sm'
                        variant='danger'
                        onClick={() => deleteHandler(check._id)}
                      >
                        <i className='fas fa-trash' />
                      </Button>
                    </td>
                  </tr>
                ))
                : myChecks?.map((check) => (
                  <tr key={check._id}>
                    <LinkContainer
                      to={`/check/${check._id}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{check._id.slice(-6)}</td>
                    </LinkContainer>
                    <td>{check.type}</td>
                    <td>
                      {check.isPaid ? (
                        check.paidAt.substring(0, 10)
                      ) : (
                        <i className='fas fa-times' style={{ color: 'red' }}></i>
                      )}
                    </td>

                    <td>
                      {check.isCompleted ? (
                        check.completedAt.substring(0, 10)
                      ) : (
                        <i className='fas fa-times' style={{ color: 'red' }}></i>
                      )}
                    </td>

                    {/* <td>
                        {check.certificate ? (
                          <Link to={check.certificate}>View File</Link>
                        ) : (
                          'None'
                        )}
                      </td> */}
                    <td>{check.user && check.user.assignedTo}</td>
                    <td>
                      <time>
                        <Moment format='ddd MMM D YYYY'>
                          {check.createdAt}
                        </Moment>
                      </time>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <CheckPaginate pages={userInfo.isAdmin ? pages : myPages} page={userInfo.isAdmin ? page : myPage} />
        </>
      )}
    </>
  );
};

export default CheckListScreen;
