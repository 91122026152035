import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Col,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { createCheck } from "../actions/checkActions";
import Message from "../components/Message";
import Loader from "../components/Loader";

const CheckForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loading, error, success } = useSelector((state) => state.checkCreate);
  const { userInfo } = useSelector((state) => state.userLogin);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [institution, setInstitution] = useState("");
  const [matricNumber, setMatricNumber] = useState("");
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorEmail, setGuarantorEmail] = useState("");
  const [guarantorPhone, setGuarantorPhone] = useState("");
  const [guarantorAddress, setGuarantorAddress] = useState("");
  const [refereeName, setRefereeName] = useState("");
  const [refereeEmail, setRefereeEmail] = useState("");
  const [refereePhone, setRefereePhone] = useState("");
  const [previousEmployerName, setPreviousEmployerName] = useState("");
  const [previousEmployerAddress, setPreviousEmployerAddress] = useState("");
  const [previousEmployerHREmail, setPreviousEmployerHREmail] = useState("");
  const [previousEmployerHRPhone, setPreviousEmployerHRPhone] = useState("");
  const [secondedAgency, setSecondedAgency] = useState("");
  const [image, setImage] = useState("");

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
    if (success) {
      navigate("/");
    }
  }, [navigate, success, userInfo]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("check", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload/check", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createCheck(
        name,
        address,
        institution,
        matricNumber,
        guarantorName,
        guarantorEmail,
        guarantorPhone,
        guarantorAddress,
        refereeName,
        refereeEmail,
        refereePhone,
        secondedAgency,
        previousEmployerName,
        previousEmployerAddress,
        previousEmployerHREmail,
        previousEmployerHRPhone,
        image
      )
    );
  };

  return (
    <>
      <Link to="/" className="btn btn-light my-3">
        Go Back
      </Link>

      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}

      <>
        <h1>Enter Bio Data </h1>

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <FormLabel>Name</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Enter Name"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Address</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  placeholder="Enter Address"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Institution</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setInstitution(e.target.value)}
                  value={institution}
                  placeholder="Enter Institution"
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Matric Number</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setMatricNumber(e.target.value)}
                  value={matricNumber}
                  placeholder="Enter Matric Number"
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
              <FormGroup>
                <FormLabel>Guarantor Name</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setGuarantorName(e.target.value)}
                  value={guarantorName}
                  placeholder="Enter Guarantor Name"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Guarantor Email</FormLabel>
                <FormControl
                  type="email"
                  onChange={(e) => setGuarantorEmail(e.target.value)}
                  value={guarantorEmail}
                  placeholder="Enter Guarantor Email"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Guarantor Phone</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setGuarantorPhone(e.target.value)}
                  value={guarantorPhone}
                  placeholder="Enter Guarantor Phone"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Guarantor Address</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setGuarantorAddress(e.target.value)}
                  value={guarantorAddress}
                  placeholder="Enter Guarantor Address"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
              <FormGroup>
                <FormLabel>Reference Name</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setRefereeName(e.target.value)}
                  value={refereeName}
                  placeholder="Enter Referee Address"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Reference Email</FormLabel>
                <FormControl
                  type="email"
                  onChange={(e) => setRefereeEmail(e.target.value)}
                  value={refereeEmail}
                  placeholder="Enter Referee Email"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Reference Phone</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setRefereePhone(e.target.value)}
                  value={refereePhone}
                  placeholder="Enter Referee Phone"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Seconded By Agency ?</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setSecondedAgency(e.target.value)}
                  value={secondedAgency}
                  placeholder="Enter Seconded Agency"
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-3">
            <Col>
              <FormGroup>
                <FormLabel> Previous Employer Name</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setPreviousEmployerName(e.target.value)}
                  value={previousEmployerName}
                  placeholder="Enter Previous Employer Name"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel> Previous Employer Address</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setPreviousEmployerAddress(e.target.value)}
                  value={previousEmployerAddress}
                  placeholder="Enter Previous Employer Address"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel> Previous Employer HR Email</FormLabel>
                <FormControl
                  type="email"
                  onChange={(e) => setPreviousEmployerHREmail(e.target.value)}
                  value={previousEmployerHREmail}
                  placeholder="Enter Previous Employer HR Email"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel> Previous Employer HR Phone</FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setPreviousEmployerHRPhone(e.target.value)}
                  value={previousEmployerHRPhone}
                  placeholder="Enter Previous Employer HR Phone"
                  required
                ></FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="py-3" controlId="Image">
                <FormLabel>Attach Copy of Certificate </FormLabel>
                <FormControl
                  type="text"
                  onChange={(e) => setImage(e.target.value)}
                  value={image}
                  placeholder="Enter File URL"
                ></FormControl>
                <FormControl
                  type="file"
                  custom
                  label="Choose file"
                  onChange={uploadFileHandler}
                  controlId="formFile"
                ></FormControl>
                {uploading && <Loader />}
              </FormGroup>
            </Col>
          </Row>

          <Button variant="warning" type="submit">
            Send Check Request
          </Button>
        </Form>
      </>
    </>
  );
};

export default CheckForm;
