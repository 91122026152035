import React, { useEffect, useState } from "react";
import FormContainer from "../components/FormContainer";
import AddCheckSteps from "../components/AddCheckSteps";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { saveRefereeDetails } from "../actions/checkActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const RefereeScreen = () => {
  // const { guarantorDetails } = useSelector((state) => state.checkForm);
  // const { refereeDetails } = useSelector((state) => state.checkForm);

  const checkForm = useSelector((state) => state.checkForm);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState(
    checkForm.refereeDetails ? checkForm.refereeDetails.name : ""
  );
  const [email, setEmail] = useState(
    checkForm.refereeDetails ? checkForm.refereeDetails.email : ""
  );
  const [phone, setPhone] = useState(
    checkForm.refereeDetails ? checkForm.refereeDetails.phone : ""
  );
  const [agency, setAgency] = useState(
    checkForm.refereeDetails ? checkForm.refereeDetails.agency : ""
  );

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (checkForm.guarantorDetails && !checkForm.guarantorDetails.name) {
      navigate("/guarantor");
    }
  }, [navigate, userInfo, checkForm.guarantorDetails]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveRefereeDetails({
        name,
        email,
        phone,
        agency,
      })
    );
    navigate("/prevemp");
  };

  return (
    <FormContainer>
      <AddCheckSteps step1 step2 step3 />
      <h3>Referee Details</h3>
      <Form onSubmit={submitHandler}>
        <FormGroup className="py-3">
          <FormLabel>Referee Name</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter Referee Name"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Referee Email</FormLabel>
          <FormControl
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Enter Referee Email"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Referee Phone</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="Enter Referee Phone"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Seconded By Agency ?</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setAgency(e.target.value)}
            value={agency}
            placeholder="Enter Seconded Agency"
          ></FormControl>
        </FormGroup>

        <Row className="mt-3">
          <Col>
            <LinkContainer to="/guarantor">
              <Button className="btn-sm">Go back</Button>
            </LinkContainer>
          </Col>
          <Col className="text-right">
            <Button type="submit" className="btn-info btn-sm">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

export default RefereeScreen;
