import React, { useState, useEffect } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import {
  getResultDetails,
  resetUpdateResult,
  updateResult,
} from '../actions/resultActions';
import { listUsers } from '../actions/userActions';

const ResultEditScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [fullName, setFullName] = useState('');
  const [courseTitle, setCourseTitle] = useState('');
  const [grade, setGrade] = useState('');
  const [matricNumber, setMatricNumber] = useState('');
  const [graduationYear, setGraduationYear] = useState('');
  const [school, setSchool] = useState('');

  const { loading, error, result } = useSelector(
    (state) => state.resultDetails
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const { error: errorUsers } = useSelector((state) => state.userList);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success,
  } = useSelector((state) => state.resultUpdate);

  useEffect(() => {
    if (userInfo.isAdmin) {
      dispatch(listUsers());
    } else {
      navigate('/login');
    }

    if (success) {
      dispatch(resetUpdateResult());
      navigate('/results');
    } else {
      if (!result || !result.fullName || id !== result._id) {
        dispatch(getResultDetails(id));
      } else {
        setFullName(result.fullName);
        setCourseTitle(result.courseTitle);
        setGrade(result.grade);
        setMatricNumber(result.matricNumber);
        setGraduationYear(result.graduationYear);
        setSchool(result.school);
      }
    }
  }, [dispatch, navigate, id, result, userInfo, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateResult({
        _id: id,
        fullName,
        courseTitle,
        grade,
        matricNumber,
        graduationYear,
        school,
      })
    );
  };

  return (
    <>
      <Link to='/results' className='btn btn-info my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Result</h1>

        {errorUsers && <Message variant='danger'>{errorUsers}</Message>}
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              <Col>
                <FormGroup controlId='fullName'>
                  <FormLabel>Full Name</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                    placeholder='Enter Full Name'
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel>Course Title</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setCourseTitle(e.target.value)}
                    value={courseTitle}
                    placeholder='Enter Course Title'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup className='py-3'>
                  <FormLabel>Grade</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setGrade(e.target.value)}
                    value={grade}
                    placeholder='Enter Grade'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className='py-3'>
                  <FormLabel>Matric Number</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setMatricNumber(e.target.value)}
                    value={matricNumber}
                    placeholder='Enter Matric Number'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup className='py-3'>
                  <FormLabel>Graduation Year</FormLabel>
                  <FormControl
                    type='number'
                    onChange={(e) => setGraduationYear(e.target.value)}
                    value={graduationYear}
                    placeholder='Enter Graduation Year'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className='py-3'>
                  <FormLabel>School</FormLabel>
                  <FormControl
                    type='text'
                    onChange={(e) => setSchool(e.target.value)}
                    value={school}
                    placeholder='Enter School'
                    required
                  ></FormControl>
                </FormGroup>
              </Col>
            </Row>

            <Button type='submit' variant='primary' className='my-3'>
              Update Result
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ResultEditScreen;
