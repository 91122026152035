import React, { useEffect, useState } from "react";
import FormContainer from "../components/FormContainer";
import AddCheckSteps from "../components/AddCheckSteps";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { savePrevempDetails } from "../actions/checkActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const PrevempScreen = () => {
  const checkForm = useSelector((state) => state.checkForm);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState(
    checkForm.prevempDetails ? checkForm.prevempDetails.name : ""
  );
  const [email, setEmail] = useState(
    checkForm.prevempDetails ? checkForm.prevempDetails.email : ""
  );
  const [phone, setPhone] = useState(
    checkForm.prevempDetails ? checkForm.prevempDetails.phone : ""
  );
  const [address, setAddress] = useState(
    checkForm.prevempDetails ? checkForm.prevempDetails.address : ""
  );

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (checkForm.refereeDetails && !checkForm.refereeDetails.name) {
      navigate("/referee");
    }
  }, [navigate, userInfo, checkForm.refereeDetails]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      savePrevempDetails({
        name,
        address,
        email,
        phone,
      })
    );
    navigate("/confirm");
  };

  return (
    <FormContainer>
      <AddCheckSteps step1 step2 step3 step4 />
      <h3>Previous Employer Details</h3>

      <Form onSubmit={submitHandler}>
        <FormGroup className="py-3">
          <FormLabel>Previous Employer Name</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Enter Previous Employer Name"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Previous Employer Address</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            placeholder="Enter Previous Employer Address"
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Previous Employer HR Email</FormLabel>
          <FormControl
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Enter Previous Employer HR Email"
            required
          ></FormControl>
        </FormGroup>

        <FormGroup className="py-3">
          <FormLabel>Previous Employer HR Phone</FormLabel>
          <FormControl
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            placeholder="Enter Previous Employer HR Phone"
            required
          ></FormControl>
        </FormGroup>

        <Row className="mt-3">
          <Col>
            <LinkContainer to="/referee">
              <Button className="btn-sm">Go back</Button>
            </LinkContainer>
          </Col>
          <Col className="text-right">
            <Button type="submit" className="btn-info btn-sm">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

export default PrevempScreen;
