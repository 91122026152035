import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import {
  Form,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import {
  getReportDetails,
  resetUpdateReport,
  updateReport,
} from "../actions/reportActions";
import { listUsers } from "../actions/userActions";

const ReportEditScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [company, setCompany] = useState("");
  const [uploading, setUploading] = useState(false);

  const { loading, error, report } = useSelector(
    (state) => state.reportDetails
  );
  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    users,
    loading: loadingUsers,
    error: errorUsers,
  } = useSelector((state) => state.userList);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success,
  } = useSelector((state) => state.reportUpdate);

  useEffect(() => {
    if (userInfo.isAdmin) {
      dispatch(listUsers());
    } else {
      navigate("/login");
    }

    if (success) {
      dispatch(resetUpdateReport());
      navigate("/reports");
    } else {
      if (!report || !report.title || id !== report._id) {
        dispatch(getReportDetails(id));
      } else {
        setTitle(report.title);
        setCompany(report.company);
        setImage(report.image);
      }
    }
  }, [dispatch, navigate, id, report, userInfo, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateReport({
        _id: id,
        title,
        company,
        image,
      })
    );
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("report", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  return (
    <>
      <Link to="/reports" className="btn btn-info my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Report</h1>

        {errorUsers && <Message variant="danger">{errorUsers}</Message>}
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FormGroup controlId="title">
              <FormLabel>Title</FormLabel>
              <FormControl
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                placeholder="Enter Name"
              ></FormControl>
            </FormGroup>

            <FormGroup className="py-3">
              <FormLabel>Company</FormLabel>
              <FormControl
                as="select"
                onChange={(e) => setCompany(e.target.value)}
                value={company}
                required
              >
                {!loadingUsers &&
                  users.map((user) => (
                    <option value={user.company}>{user.company}</option>
                  ))}
              </FormControl>
            </FormGroup>

            <FormGroup className="py-3" controlId="Image">
              <FormLabel>Report File </FormLabel>
              <FormControl
                type="text"
                onChange={(e) => setImage(e.target.value)}
                value={image}
                placeholder="Enter File URL"
              ></FormControl>
              <FormControl
                type="file"
                custom
                label="Choose file"
                onChange={uploadFileHandler}
                controlId="formFile"
              ></FormControl>
              {uploading && <Loader />}
            </FormGroup>

            <Button type="submit" variant="primary" className="my-3">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ReportEditScreen;
