export const RESULT_LIST_REQUEST = 'RESULT_LIST_REQUEST';
export const RESULT_LIST_SUCCESS = 'RESULT_LIST_SUCCESS';
export const RESULT_LIST_FAIL = 'RESULT_LIST_FAIL';

export const RESULT_DETAILS_REQUEST = 'RESULT_DETAILS_REQUEST';
export const RESULT_DETAILS_SUCCESS = 'RESULT_DETAILS_SUCCESS';
export const RESULT_DETAILS_FAIL = 'RESULT_DETAILS_FAIL';
export const RESULT_DETAILS_RESET = 'RESULT_DETAILS_RESET';

export const RESULT_DELETE_REQUEST = 'RESULT_DELETE_REQUEST';
export const RESULT_DELETE_SUCCESS = 'RESULT_DELETE_SUCCESS';
export const RESULT_DELETE_FAIL = 'RESULT_DELETE_FAIL';

export const RESULT_CREATE_REQUEST = 'RESULT_CREATE_REQUEST';
export const RESULT_CREATE_SUCCESS = 'RESULT_CREATE_SUCCESS';
export const RESULT_CREATE_FAIL = 'RESULT_CREATE_FAIL';
export const RESULT_CREATE_RESET = 'RESULT_CREATE_RESET';

export const RESULT_BULK_CREATE_REQUEST = 'RESULT_BULK_CREATE_REQUEST';
export const RESULT_BULK_CREATE_SUCCESS = 'RESULT_BULK_CREATE_SUCCESS';
export const RESULT_BULK_CREATE_FAIL = 'RESULT_BULK_CREATE_FAIL';
export const RESULT_BULK_CREATE_RESET = 'RESULT_BULK_CREATE_RESET';

export const RESULT_UPDATE_REQUEST = 'RESULT_UPDATE_REQUEST';
export const RESULT_UPDATE_SUCCESS = 'RESULT_UPDATE_SUCCESS';
export const RESULT_UPDATE_FAIL = 'RESULT_UPDATE_FAIL';
export const RESULT_UPDATE_RESET = 'RESULT_UPDATE_RESET';
